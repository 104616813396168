/* eslint-disable func-names */
// import "./lib/selectize";
// import ionRangeSlider from "ion-rangeslider";
import 'fslightbox'; // eslint-disable-line
import "easy-autocomplete"; // eslint-disable-line
import Choices from "choices.js"; // eslint-disable-line
import MmenuLight from 'mmenu-light';

export default function controls() {

  const filterToggle = document.querySelector('.catalog-filter__toggle');
  const filterClose = document.querySelector('.page-sidebar__close');
  const filterWrapper = document.querySelector('#catalog-sidebar');

  const toggleFilter = () => {
    if (filterWrapper.classList.contains('fixed')) {
      filterWrapper.classList.remove('fixed');
    } else {
      filterWrapper.classList.add('fixed');
    }
  }
  if (filterToggle) {
    filterToggle.addEventListener('click', (e) => {
      e.preventDefault();
      toggleFilter();
    });
  }
  if (filterClose) {
    filterClose.addEventListener('click', (e) => {
      e.preventDefault();
      if (filterWrapper) {
        filterWrapper.classList.remove('fixed');
      }
    });
  }

  const menu = new MmenuLight(document.querySelector( "#mmenu" ));

  const navigator = menu.navigation();
  const drawer = menu.offcanvas();

  document.querySelector( 'a[href="#mmenu"]' ).addEventListener( 'click', ( evnt ) => {
      evnt.preventDefault();
      drawer.open();
  });

  document.querySelectorAll('[data-choice]').forEach(el => {
    const singleNoSearch = new Choices(el, {
      allowHTML: true,
      itemSelectText: '',
      searchEnabled: false,
    });
  })

  document.querySelectorAll('[data-choice-search]').forEach(el => {
    const singleNoSearch = new Choices(el, {
      allowHTML: true,
      itemSelectText: '',
      searchEnabled: true,
    });
  })


  $(".form-search input[type='text']").each(function() {
    const $item = $(this)


  const jsonUrl = $item.data("url");
  // console.log(jsonUrl);
  $item.easyAutocomplete({
    url (phrase) {
      return `${jsonUrl}?phrase=${phrase}&format=json`;
    },
    getValue: "name",
    cssClasses: "search-head",
    template: {
      type: "custom",
      method(value, item) {
        return `<a href='${item.uri}' ><span class='search-img'><img src='${item.icon}' /></span><span class='search-name'>${value}</span><span class='search-cost'>${item.cost}</span></a>`;
      },
    },
    list: {
      maxNumberOfElements: 10,
      match: {
        enabled: false,
      },
      sort: {
        enabled: true,
      },
    },
  });

  $item.closest('form').on("submit", function (e) {
    e.preventDefault();
    window.location = `/search?q=${$item.val()}`;
  });
});

  class QuantityCounter {
    constructor(element) {
      this.el      = element;
      this.count   = 1;

      this.init();
    }

    init() {
      this.inputEl = this.el.querySelector('[data-counter-input]');
      this.plusEl  = this.el.querySelector('[data-counter-plus]');
      this.minusEl = this.el.querySelector('[data-counter-minus]');

      this.count = Number.parseInt(this.inputEl.value, 10);

      this.plusEl.addEventListener('click', (e) => this.plus());
      this.minusEl.addEventListener('click', (e) => this.minus());
      this.inputEl.addEventListener('change', (e) => this.update(e.target.value));

      this.update();
    }

    plus() {
      this.count += 1;
      this.update();
    }

    minus() {
      this.count -= 1;
      this.update();
    }

    update(val) {
      if (val) this.count = Number.parseInt(val, 10);
      const limit = this.count <= 999;
      this.count = (this.count >= 1 && limit) ? this.count : 1;
      this.inputEl.value = this.count;

      if (!val) {
        this.inputEl.dispatchEvent(new Event('change'));
      }
    }


  }

  const counters = document.querySelectorAll('.quantity-counter');
  counters.forEach(el => new QuantityCounter(el));


    (function sidebarNavToggle() {
      const toggleClass       = 'sidebar-nav__toggle';
      const parentClass       = 'sidebar-nav__item';
      const itemClassActive = `${parentClass}_active`;
      const groupElements   = document.querySelectorAll(`.${toggleClass}`);

      // console.log(groupElements);

      groupElements.forEach(el => {
        el.addEventListener('click', (e) => {
          e.preventDefault();
          // const parentEl = el.closest(`.${parentClass}`);
          // if (parentEl) {
          //   parentEl.classList.toggle(itemClassActive);
          // }
        })
      })

    })();



    (function() {
      const productMainImage = document.querySelector('.product-image__main-image')
      $('.product-image__thumbs-item').on('click', function() {
        $('.product-image__thumbs-item').removeClass('active');
        this.classList.add('active');
        productMainImage.src = this.querySelector('img').src;
      })
    })();

    // (function navToggle() {
    //     const btn = document.querySelector('.menu-toggle');
    //     // const menuList = document.querySelector('.mobile-menu-list');
    //     const header = document.querySelector('.header');

    //     btn.addEventListener('click', function() {
    //         header.classList.toggle('menu-open')
    //     });

    //     // menuList.addEventListener('click', function(e) {
    //     //     if(e.target.classList.contains('mobile-menu-list-item')) {
    //     //         header.classList.toggle('menu-open')
    //     //     }

    //     // })


    // })();

    // (function headerSticky() {
    //     const {body} = document;
    //     const scrollUp = "scroll-up";
    //     const scrollDown = "scroll-down";
    //     const header = document.querySelector('.header')
    //     let lastScroll = 0;

    //     window.addEventListener("scroll", () => {
    //         if(header.classList.contains('menu-open')) return;
    //         const currentScroll = window.pageYOffset;
    //         if (currentScroll <= 300) {
    //             body.classList.remove(scrollUp);
    //             return;
    //         }

    //         if (currentScroll > lastScroll && currentScroll > 300 && !body.classList.contains(scrollDown)) {
    //             // down
    //             body.classList.remove(scrollUp);
    //             body.classList.add(scrollDown);
    //         } else if (currentScroll < lastScroll && currentScroll > 300 && body.classList.contains(scrollDown)) {
    //             // up
    //             body.classList.remove(scrollDown);
    //             body.classList.add(scrollUp);
    //         }
    //         lastScroll = currentScroll;
    //     });
    // })();


    // (function getContent() {

    //     function evalJSFromHtml(html) {
    //       const newElement = document.createElement('div');
    //       newElement.innerHTML = html;

    //       const scripts = newElement.getElementsByTagName("script");
    //       for (let i = 0; i < scripts.length; ++i) {
    //         const script = scripts[i];
    //         // eslint-disable-next-line no-eval
    //         eval(script.innerHTML);
    //       }
    //     }

    //     $('.ajax_load').on('click', function(e) {
    //         e.preventDefault();
    //         const activeClass = this.dataset.activeClass || 0
    //         if(this.classList.contains(activeClass)) return;
    //         const loadURL = this.dataset.ajaxFrom
    //         if(!loadURL) return;
    //         const appending = this.dataset.ajaxAppend || 0
    //         const pageTo = this.dataset.ajaxTo || null
    //         const hideIt = this.dataset.ajaxHide || null
    //         const wrapper = document.querySelector(pageTo) || null
    //         const hideThis = document.querySelector(hideIt)
    //         if(wrapper) {
    //             wrapper.classList.add('loader')
    //         }
    //         if(activeClass) {
    //             this.parentElement.querySelectorAll('.ajax_load').forEach(n => n.classList.toggle(activeClass, n === e.target));
    //         }
    //         // this.classList.add('active')

    //         $.ajax({
    //         type: "GET",
    //         url: loadURL,
    //         dataType: "html",
    //         success(data) {

    //             if(appending === '1') {
    //             wrapper.insertAdjacentHTML('beforeend', data);
    //             wrapper.classList.remove('loader')
    //             } else {
    //             wrapper.innerHTML = data;
    //             wrapper.classList.remove('loader')
    //             }
    //             evalJSFromHtml(data);
    //                 if(hideThis) {
    //                 hideThis.style.display = 'none';
    //             }

    //         }
    //         });
    //     });
    // })();

}
