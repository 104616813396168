import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/scss/'
import sliders from './sliders';
import modals from './modals';
import controls from './controls';
import map from './map';
// import animation from './animation';

// import checkUserSystem from "./lib/checkUserSystem";


// const collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
// const collapseList = collapseElementList.map(function (collapseEl) {
//   return new Collapse(collapseEl)
// })

global.ww = window.innerWidth;
global.wh = window.innerHeight;

if(window.location.pathname === "/") document.body.classList.add("home")

$(document).ready(() => {

  // console.log(Collapse); // eslint-disable-line


    document.body.classList.add("ready")
    global.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    controls()
    sliders()
    map()
    modals()
    // animation()

    const allResponsives = () => {
        global.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        // console.log('isMobile:', global.isMobile);
    };
    allResponsives();

    window.addEventListener('resize', () => {
        global.ww = window.innerWidth;
        global.wh = window.innerHeight;
        allResponsives();
    });

    $(document).on("click", ".hero-block__left .hero-block__inner", () => {
        $(".hero__slider").addClass("active from-left")
    })
    $(document).on("click", ".hero-block__right .hero-block__inner", () => {
      $(".hero__slider").addClass("active from-right")
    })
    $(document).on("click", ".close-slider", () => {
        $(".hero__slider").removeClass("active").removeClass("from-right").removeClass("from-left")
    })

  const head = document.querySelector("head");
  let theme = "light";
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) theme = "dark";
  head.insertAdjacentHTML('beforeend', `
    <link rel="apple-touch-icon" sizes="57x57" href="/img/favicons/${theme}/apple-icon-57x57.png">
    <link rel="apple-touch-icon" sizes="60x60" href="/img/favicons/${theme}/apple-icon-60x60.png">
    <link rel="apple-touch-icon" sizes="72x72" href="/img/favicons/${theme}/apple-icon-72x72.png">
    <link rel="apple-touch-icon" sizes="76x76" href="/img/favicons/${theme}/apple-icon-76x76.png">
    <link rel="apple-touch-icon" sizes="114x114" href="/img/favicons/${theme}/apple-icon-114x114.png">
    <link rel="apple-touch-icon" sizes="120x120" href="/img/favicons/${theme}/apple-icon-120x120.png">
    <link rel="apple-touch-icon" sizes="144x144" href="/img/favicons/${theme}/apple-icon-144x144.png">
    <link rel="apple-touch-icon" sizes="152x152" href="/img/favicons/${theme}/apple-icon-152x152.png">
    <link rel="apple-touch-icon" sizes="180x180" href="/img/favicons/${theme}/apple-icon-180x180.png">
    <link rel="icon" type="image/png" sizes="192x192"  href="/img/favicons/${theme}/android-icon-192x192.png">
    <link rel="icon" type="image/png" sizes="32x32" href="/img/favicons/${theme}/favicon-32x32.png">
    <link rel="icon" type="image/png" sizes="96x96" href="/img/favicons/${theme}/favicon-96x96.png">
    <link rel="icon" type="image/png" sizes="16x16" href="/img/favicons/${theme}/favicon-16x16.png">
    <link rel="manifest" href="/img/favicons/${theme}/manifest.json">
    <meta name="msapplication-TileColor" content="#ffffff">
    <meta name="msapplication-TileImage" content="/img/favicons/${theme}/ms-icon-144x144.png">
    <meta name="theme-color" content="#ffffff">
   `);
});
