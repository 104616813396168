
// eslint-disable-line import/no-unresolved
import ymaps from 'ymaps';

export default function map() {


    class initYMap {
      constructor(selector = '#map', collection = null) {
        this.selector = selector
        this.objects = collection

        this.init()
      }

      init() {
        const $this = this;
        // const offsetSize = document.querySelector('.main-contacts-info').clientWidth
        const mapLinks = document.querySelectorAll('.contacts-info__item-wrap[data-coord]');
        const mapElement = document.querySelector($this.selector);
        if(!mapElement) return;
        const mapLang = mapElement.dataset.mapLang || 'ru_RU';
        const mapZoom = mapElement.dataset.mapZoom || 16;
        const mapIcon = mapElement.dataset.mapIcon || 'img/icons/mapIcon.svg';
        const marginLeft = 20;
        ymaps
        .load(`https://api-maps.yandex.ru/2.1/?lang=${mapLang}`)
        .then(maps => {
          const myMap = new maps.Map(mapElement, {
            center: $this.objects.features[0].geometry.coordinates,
            zoom: mapZoom,
            controls: ['zoomControl']
          }, {
            maxZoom: 16,
            searchControlProvider: 'yandex#search'
          });


          myMap.margin.setDefaultMargin([0,0,0,marginLeft]);

          const objectManager = new maps.ObjectManager();

          objectManager.objects.options.set({
            iconLayout: 'default#image',
            iconImageHref: mapIcon,
            iconImageSize: [29, 41],
            iconImageOffset: [-14, -41]
          });

          objectManager.add($this.objects);
          myMap.geoObjects.add(objectManager);
          myMap.setBounds(myMap.geoObjects.getBounds(), { useMapMargin: true,});
          myMap.container.fitToViewport();

          myMap.behaviors.disable('scrollZoom');

          function panTo(e) {
            const coord = JSON.parse(`[${this.dataset.coord}]`) || null
            if(!coord) return;
            e.preventDefault();
            myMap.panTo(coord, {
              flying: true,
              duration: 1000
            }).then(function () {
              myMap.setZoom(15, {checkZoomRange: true, duration: 1000, useMapMargin: true});
            });


          }
          if(!mapLinks) return;
          mapLinks.forEach(link => {
            link.addEventListener('click', panTo)
          });

        })
        .catch(error => console.log('Failed to load Yandex Maps', error));


      }

    }
    // const initMap = (selector = '#map', collection = null) => {


    // }

    window.initYMap = initYMap
  }

