import "./lib/jquery.maskedinput"
import datepicker from 'js-datepicker'
/* eslint-disable */
export default function modals() {
    $(document).on("click", ".toggle-modal", function(e){
      let place = $(e.currentTarget).data("place")
      if(!place) place = "Не выбран"
      $("#form-place").val(place)
    })

    const addTimes = (nowHours = 0) => {
      let timePicker = document.getElementById("timePicker")
      $("#timePicker")
        .find('option')
        .remove()
        .end()
      ;
      timePicker.add(new Option("Время", "0"))
      let timeArray = []
      let timeStart = 12
      let timeEnd = 23
      for(let i = timeStart; i <= timeEnd; i++){
        if(Number(i) > Number(nowHours)){
          timeArray.push(
            i + ":00",
            i + ":30"
          )
        }
      }
      timeArray.map(item => {
        timePicker.add(new Option(item))
      })
      setSelect()
    }

    const picker = datepicker("#datePicker", {
      startDay: 1,
      minDate: new Date(),
      startDate: new Date(),
      customDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      customMonths: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      disableYearOverlay: true,
      formatter: (input, date, instance) => {
        input.value = date.toLocaleDateString("ru-RU")
      },
      onSelect: instance => {
        let now = new Date();
        let startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        let selected = instance.dateSelected
        let selectedDay = new Date(selected.getFullYear(), selected.getMonth(), selected.getDate());
        if(Number(startOfDay / 1000) === Number(selectedDay / 1000)){
          let nowHours = now.getHours()
          addTimes(nowHours)
        } else {
          addTimes()
        }
      },
    })

    $(document).ready(() => {
      const validateForm = (e) => {
        e.preventDefault();
        [].forEach.call(document.querySelectorAll("form .error"), k => k.remove());
        let error = false;
        const form = e.target;
        [].forEach.call(form, i => {
          if(i.classList.contains("qs-overlay-year")) return;
          if(i.nodeName === "INPUT" || i.nodeName === "SELECT") {
            if(i.value.length === 0 || i.value == 0) {
              let errorNode = document.createElement("div");
              errorNode.classList.add("error");
              let errorText = document.createTextNode("Поле обязательно для заполнения");
              errorNode.appendChild(errorText);
              i.after(errorNode);
              error = true;
            }
          }
        });
        if(!error) {
          const action = form.getAttribute("action");
          let facts = new FormData(form);
          fetch(action, {
            method : "POST",
            body: facts,
            headers: {
              "X-Requested-With":"XMLHttpRequest"
            }
          }).then(response => {
            console.log(response)
            if(response.status === 200){
              let successNode = document.createElement("div");
              successNode.classList.add("success");
              let successText = "Спасибо за заявку!<br/>Наш менеджер с вами свяжется.";
              successNode.insertAdjacentHTML("beforeend", successText);
              form.appendChild(successNode);
              form.reset();
            } else {
              console.log("Произошла ошибка при отправке.")
            }
          });
        }
      }
      let bookingForm = document.getElementById("bookingForm");
      bookingForm.addEventListener("submit", validateForm);
    })

    const setSelect = () => {
      $(".select-selected, .select-items").remove()
      /* https://www.w3schools.com/howto/howto_custom_select.asp */
      let x;
      let i;
      let j;
      let l;
      let ll;
      let selElmnt;
      let a;
      let b;
      let c;
      /* Look for any elements with the class "custom-select": */
      x = document.getElementsByClassName("custom-select");
      l = x.length;
      for (i = 0; i < l; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        ll = selElmnt.length;
        if(ll == 1) x[i].classList.add("empty")
        else x[i].classList.remove("empty")
        /* For each element, create a new DIV that will act as the selected item: */
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        /* For each element, create a new DIV that will contain the option list: */
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        for (j = 1; j < ll; j++) {
          /* For each option in the original select element,
          create a new DIV that will act as an option item: */
          c = document.createElement("DIV");
          c.innerHTML = selElmnt.options[j].innerHTML;
          c.addEventListener("click", function (e) {
            /* When an item is clicked, update the original select box,
            and the selected item: */
            let y;
            let i;
            let k;
            let s;
            let h;
            let sl;
            let yl;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            sl = s.length;
            h = this.parentNode.previousSibling;
            for (i = 0; i < sl; i++) {
              if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                yl = y.length;
                for (k = 0; k < yl; k++) {
                  y[k].removeAttribute("class");
                }
                this.setAttribute("class", "same-as-selected");
                break;
              }
            }
            h.click();
          });
          b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
          /* When the select box is clicked, close any other select boxes,
          and open/close the current select box: */
          e.stopPropagation();
          closeAllSelect(this);
          this.nextSibling.classList.toggle("select-hide");
          this.classList.toggle("select-arrow-active");
        });
      }
    }

    function closeAllSelect(elmnt) {
      /* A function that will close all select boxes in the document,
      except the current select box: */
      let x; let y; let i; let xl; let yl; const arrNo = [];
      x = document.getElementsByClassName("select-items");
      y = document.getElementsByClassName("select-selected");
      xl = x.length;
      yl = y.length;
      for (i = 0; i < yl; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i)
        } else {
          y[i].classList.remove("select-arrow-active");
        }
      }
      for (i = 0; i < xl; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("select-hide");
        }
      }
    }
    setSelect()

    /* If the user clicks anywhere outside the select box,
    then close all select boxes: */
    document.addEventListener("click", closeAllSelect);

    // $('input[type="tel"]').mask("+7 (999) 999-99-99");
    // (function inputPhoneMask() {
    //     $('input[type="tel"]').mask("+7 (999) 999-99-99", {
    //       completed() {
    //         const formGroup = $(this).closest(".form-group");
    //         formGroup[0].classList.remove("has-danger");
    //         formGroup.find(".pristine-error").hide();
    //       },
    //     });
    //   })();


    // (function formValidation() {
    //     const formList = document.querySelectorAll("[data-form-validate]");

    //     formList.forEach((form) => {
    //       const validation = new Pristine(form);
    //       const successMessage = form.querySelector(".form-success__message");
    //       // const wrapper = form.closest('.request__wrapper') || form.closest('.request-modal')
    //       form.addEventListener("submit", function (e) {
    //         // console.log('submit');
    //         e.preventDefault();
    //         const formData = new FormData(this);
    //         const valid = validation.validate();

    //         if (valid) {
    //           $.ajax({
    //             // dataType: "json",
    //             type: form.method,
    //             url: form.action,
    //             data: formData,
    //             cache: false,
    //             processData: false,
    //             contentType: false,
    //             success(data) {
    //                 form.reset();
    //                 successMessage.innerHTML = 'Ваша заявка успешно отправлена!';
    //                 successMessage.style.display = "block";
    //                 // console.log('success!');
    //                 // wrapper.classList.remove('loader');
    //             },
    //             error(request, status, error) {},
    //           });
    //         } else {
    //           successMessage.style.display = "none";
    //         }
    //       });
    //     });
    //   })();

}
